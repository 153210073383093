
import { Component,  OnInit} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AuthAmplifyService } from 'src/app/shared/service/auth-amplify.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { environment } from 'src/environments/environment';
import { SharingDataService } from 'src/app/shared/service/sharing-data.service';
import { TranslationService } from 'src/app/shared/service/translation.service';
import { AppConstants } from 'src/app/shared/constants/constants';
import { LanguageService } from 'src/app/shared/service/language.service';
import { Router } from '@angular/router';
import { StoreManagerService } from 'src/app/shared/store-manager/services/store-manager.service';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent implements OnInit {
 
  isMenuVisible:boolean = false;
  scrolled60:any;
  above60:boolean=false
  aliasSelected:any;
  loggedInUser: Observable<any>;
  logoutUrl: string = environment.logout;
  isLocationsVisible = environment.enableLocations;
  selectedLanguage: string;
  isMobileMenuVisible: boolean = false;
  isProfileVisible:boolean=false
  subscriptions: Subscription[] = [];
  profileImage:any;
  translationData: any;
  commonLabels = AppConstants.COMMON_TITLE_LABELS
  cacheBustedUrl:string='';

  public isLanguageSelctionEnabled = this._translationService.featureFlags?.locations.enableLanguageSelection;
  public showLanguageSelectionModal: boolean = false;
  public userLanguageForDisplay: string = 'English';

  constructor(authenticationService: AuthenticationService, 
    private authAmplifyService: AuthAmplifyService,
    private SharingData:SharingDataService, 
    private _translationService: TranslationService,
  private _langauageService: LanguageService,
  private router: Router, private _storeManagerService: StoreManagerService) { 
    this.loggedInUser = authenticationService.isLoggedIn();

    let selectedLanguage = this._storeManagerService.getUserSelectedLanguageFromStore();
    if (!selectedLanguage) {
      selectedLanguage = 'en-GB';
    }
    this.selectedLanguage = selectedLanguage;
    this.isLanguageSelctionEnabled = this._translationService.featureFlags?.locations?.enableLanguageSelection;
  }
 

  ngOnInit(): void {
    this.userLanguageForDisplay = this.getUserSelectedLanguage();
    this._translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
    this.subscriptions.push(this.SharingData.getAliasList().subscribe((data:any)=>{
      if(data){
      this.aliasSelected=data
      }
      }
    ))
    this.subscriptions.push(this.SharingData.getProfileDetailsClicked().subscribe((value:any)=>{
      if(value){
        this.isProfileVisible=false
      }
    }))
    localStorage.getItem('profile');
    if(localStorage.getItem('profile')){
      this.aliasSelected=true
    }
    this.subscriptions.push(this.SharingData.getProfileChanged().subscribe((data:any)=>{
      if(data){
        this.handleCachedUrl()
      }
    }))
   this.handleCachedUrl()

  }

  handleCachedUrl(){
    this.profileImage=localStorage.getItem('profileImage')
    this.cacheBustedUrl = `${this.profileImage}?cb=${new Date().getTime()}`;
  }
  
  async logout() {
    this.removeIndexedDb();
    this.authAmplifyService.logout();
  }
  
  async removeIndexedDb(){
    const dbs = await window.indexedDB.databases()
    dbs.forEach((db:any) => { window.indexedDB.deleteDatabase(db.name) })
    this.toggleMobileMenu(); // menu should be hidden
  }

  public switchLanguage(language:string): void {
    this._storeManagerService.setUserLanguageToStore(language);
    const fullURL = window.location.href.split('/');
    fullURL[3] = language;
    window.location.href = fullURL.join('/');
  }

  toggleMobileMenu(){
    this.isMobileMenuVisible = false;
    this.SharingData.setMobileMenuStatus(false);
    this._langauageService.showLanguageSelectionModal.next(false);
  }
  myProfile(){
    this.isProfileVisible=!this.isProfileVisible
  }
  closeDialog(event:any){
    event.stopPropagation()
    this.SharingData.setProfileSelected(true)
    this.isProfileVisible=false
  }

  public getUserSelectedLanguage(): string {
    const defaultLang = () => {
      const lang = selectedLanguage === 'de' ? 'German' : 'English';
      return lang
    }
    const selectedLanguage = this._storeManagerService.getUserSelectedLanguageFromStore();
    const supportedLangList = this._translationService.supportedLanguagesForDisplay;
    const langName = supportedLangList.find((lang: any) => lang.localeCode === selectedLanguage);
    return langName && langName.localeDescription ? langName.localeDescription.split('(')[0] : defaultLang();
  }

  public openLanguageSelection() {
    this._langauageService.showLanguageSelectionModal.next(true);
  }
  /**
   * @description This method is used to reload the same active route on click of the same tab
   * For ex: If user is on any child route and clicks on the tab from header, it will bring the user
   * back to same main page
   */
  public reloadActiveRouteForLocations(): void {
    const initalMapData = this._storeManagerService.getMapDataOnLoad();
    this._storeManagerService.setCurrentMapDataToStore(initalMapData)
    this.router.navigateByUrl('/').then(() => {
      this.router.navigateByUrl('/locations');
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

}


