{
  "1005": "BP_EM_EVENT_ND",
  "1202": "BP_EM_EXERCISE_ND",
  "1207": "BP_EM_EXERCISE_ENAE",
  "1302": "BP_EM_RACE_NF",
  "1402": "BP_EM_PARTICIPANT_EAE",
  "1403": "BP_EM_PARTICIPANT_PDE",
  "1404": "BP_EM_PARTICIPANT_HDR",
  "1004": "BP_EM_EVENT_PECE",
  "1205": "BP_EM_EXERCISE_PECM",
  "1912": "BP_AC_TOKEN_E",
  "1901":  "BP_AC_ISE",
  "1502": "BP_EM_SC_ISM",
  "1110": "BP_EM_EXERCISE_CGBE",
  "1918": "BP_EM_PNF",
  "1701": "BP_PM_FM_CAP",
  "1913": "BP_UA",
  "1410":"BP_EM_NO_RACE_FOUND",
  "1501":"BP_EM_ACCESS_DENIED",
  "1014":"BP_EM_NO_ACCESS",
  "1408":"BP_EM_ANONYMOUS_PARTICIPANT"
}