        <!--Navigation starts here-->
        <div id="header-nav-set">
            <ng-container *ngIf="isProfileVisible">
                <div class="profile-switch">
                    <app-profile-switch></app-profile-switch>
                </div>   
            </ng-container>
            <div id="header-top-row-r" class="hd-r-row">
                <div id="lang-set">
                    <ul class="language-menu">
                        <ng-container *ngIf="!isLanguageSelctionEnabled; else langSelectionBlock">
                            <li [ngClass]="{'active': selectedLanguage === 'de'}" (click)="switchLanguage('de')">
                                <a href="javascript:void(0)" hreflang="de-DE" title="Deutsch">
                                    <span class="desk-only">{{'de' | uppercase}}</span>
                                    <span class="mob-only">Deutsch</span>
                                </a>
                            </li>
                            <li [ngClass]="{'active': selectedLanguage === 'en'}"  (click)="switchLanguage('en')">
                                <a href="javascript:void(0)" hreflang="en-US" title="English">
                                    <span class="desk-only">{{'en' | uppercase}}</span>
                                    <span class="mob-only">English</span>
                                </a>
                            </li>
                        </ng-container>
                        <ng-template #langSelectionBlock>
                            <li class="lang-text active">
                                <a (click)="openLanguageSelection()" id="language-text-link">
                                    <span class="desk-only desk-selected-lang">{{userLanguageForDisplay | titlecase}}</span>
                                    <span class="mob-only mob-selected-lang">{{userLanguageForDisplay | titlecase}}</span>
                                </a>
                            </li>
                        </ng-template>
                    </ul>
                </div>
            </div>

            <div id="header-bottom-row-r" class="hd-r-row">
                <div id="nav-main">
                    <ul>
            
                            <li class="active"><a href="#" *ngIf="isLocationsVisible" [routerLink]="['/'+selectedLanguage+'/locations']" [routerLinkActive]="'active'" (click)="removeIndexedDb(); toggleMobileMenu(); reloadActiveRouteForLocations()">{{commonLabels.LOCATION_TITLE | customTranslation: translationData | uppercase}}</a></li>
                            <li><a href="#" [routerLink]="['/'+selectedLanguage+'/events']" [routerLinkActive]="'active'" (click)="toggleMobileMenu()">{{commonLabels.EVENTS_TITLE| customTranslation: translationData | uppercase}}</a></li>
                            <li>
                        <button id="user-profile" class="desk-only profile-btn" (click)="myProfile()" appClickOutside (clickOutside)="closeDialog($event)">
                            <span [ngClass]="isProfileVisible?'border-red prof-image':'prof-image'">
                                <img [src]="cacheBustedUrl" alt="">
                            </span>
                           
                        </button>
                        </li>
            
                    </ul>
                </div>
                
                
            </div>
            
    
            
        </div>
        <!--Navigation Ends here-->

        